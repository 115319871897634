<template>
  <b-overlay
    :show="show"
    variant="transparent"
    opacity="0.99"
    blur="5px"
    rounded="sm"
  >
    <template #overlay>
      <div class="text-center">
        <b-icon-controller
          font-scale="3"
          animation="cylon"
        />
        <p id="cancel-label">
          กรุณารอสักครู่...
        </p>
      </div>
    </template>
    <div>
      <b-row class="match-height">
        <b-col
          md="2"
          cols="12"
          class="mb-1"
        >
          <div>
            <div class="card-cradit white">
              <div class="p-1">
                <span>เครดิต</span>
                <h3>0.00</h3>
              </div>
              <div class="card-b d-flex justify-content-between">
                <small>-</small>
                <small>เครดิตสมาชิก 0.00</small>
              </div>
            </div>

          </div>
        </b-col>
        <b-col
          md="2"
          cols="12"
          class="mb-1"
        >
          <div>
            <div class="card-cradit-white white ">
              <div class="p-1">
                <span>ฝากเงิน</span>
                <h3>{{ Commas(today.deamount.toFixed(2)) }}</h3>
              </div>
              <div class="card-b d-flex justify-content-between">
                <small>{{ Commas(today.nodeposit) }} รายการ</small>
                <small>{{ Commas(today.memdeposit) }} สมาชิก</small>
              </div>
            </div>

          </div>
        </b-col>
        <b-col
          md="2"
          cols="12"
          class="mb-1"
        >
          <div>
            <div class="card-cradit-white white ">
              <div class="p-1">
                <span>ถอนเงิน</span>
                <h3>{{ Commas((today.weamount).toFixed(2)) }}</h3>
              </div>
              <div class="card-b d-flex justify-content-between">
                <small>0 รายการ</small>
                <small>0 สมาชิก</small>
              </div>
            </div>

          </div>
        </b-col>
        <b-col
          md="2"
          cols="12"
          class="mb-1"
        >
          <div>
            <div class="card-cradit-white white ">
              <div class="p-1">
                <span>โบนัส</span>
                <h3>{{ Commas((today.debonus).toFixed(2)) }}</h3>
              </div>
              <div class="card-b d-flex justify-content-between">
                <small>-</small>
                <small>โบนัสทั้งหมด 0.00</small>
              </div>
            </div>

          </div>
        </b-col>
        <b-col
          md="2"
          cols="12"
          class="mb-1"
        >
          <div>
            <div class="card-cradit-white white ">
              <div class="p-1">
                <span>กำไร</span>
                <h3>{{ Commas((today.total).toFixed(2)) }}</h3>
              </div>
              <div class="card-b d-flex justify-content-between">
                <small>-</small>
                <small>กำไรทั้งหมด 0.00</small>
              </div>
            </div>

          </div>
        </b-col>
        <b-col
          md="2"
          cols="12"
          class="mb-1"
        >
          <div>
            <div class="card-cradit-white white ">
              <div class="p-1">
                <span>Truemoney</span>
                <h3> {{ Commas(sunTrue) }}</h3>
              </div>
              <div class="card-b d-flex justify-content-between">
                <small>-</small>
                <small>จำนวน {{ Commas(today.truemoney.length) }} รายการ</small>
              </div>
            </div>

          </div>
        </b-col>
        <b-col
          md="2"
          cols="12"
          class="mb-1"
        >
          <div>
            <div class="card-cradit-blue">
              <div class="p-1">
                <span>สมัครและเติมวันนี้</span>
                <h3 class="text-white">
                  {{ Commas(today.regisnew) }}
                </h3>
              </div>
              <div class="card-b d-flex justify-content-between">
                <small> -</small>
                <small> -</small>
              </div>
            </div>

          </div>
        </b-col>
        <b-col
          md="2"
          cols="12"
          class="mb-1"
        >
          <div>
            <div class="card-cradit-pink">
              <div class="p-1">
                <span>Active วันนี้</span>
                <h3 class="text-white">
                  {{ Commas(today.activeNew) }}
                </h3>
              </div>
              <div class="card-b d-flex justify-content-between">
                <small>-</small>
                <small>-</small>
              </div>
            </div>

          </div>
        </b-col>
        <b-col
          md="2"
          cols="12"
          class="mb-1"
        >
          <div>
            <div class="card-cradit-orange">
              <div class="p-1">
                <span>เติมเงินวันนี้</span>
                <h3 class="text-white">
                  {{ Commas(today.memdeposit) }} <small>คน</small>
                </h3>
              </div>
              <div class="card-b d-flex justify-content-between">
                <small>-</small>
                <small>จำนวน {{ Commas(today.deamount.toFixed(2)) }} บาท</small>
              </div>
            </div>

          </div>
        </b-col>
        <b-col
          md="2"
          cols="12"
          class="mb-1"
        >
          <div>
            <div class="card-cradit-yellow">
              <div class="p-1">
                <span>เติมเงินครั้งแรก</span>
                <h3>
                  {{ today.memdepositfirst }} <small>คน</small>
                </h3>
              </div>
              <div class="card-b d-flex justify-content-between">
                <small>-</small>
                <small>จำนวน {{ Commas(today.memdepositfirstSum) }} บาท</small>
              </div>
            </div>

          </div>
        </b-col>
        <b-col
          md="2"
          cols="12"
          class="mb-1"
        >
          <div>
            <div class="card-cradit-white white">
              <div class="p-1">
                <span>SCB SMS</span>
                <h3 class="text-white">
                  <small>รวม</small> &nbsp; {{ Commas(scbsms.total) }}
                </h3>
              </div>
              <div class="card-b d-flex justify-content-between">
                <small>เติมแล้ว {{ Commas(scbsms.success) }}บาท</small>
                <small>เติมไม่ได้ {{ Commas(scbsms.waiting) }}บาท</small>
              </div>
            </div>

          </div>
        </b-col>
        <b-col
          md="2"
          cols="12"
          class="mb-1"
        >
          <div>
            <div class="card-cradit-white white">
              <div class="p-1">
                <span>KBANK SMS</span>
                <h3 class="text-white">
                  <small>รวม</small> &nbsp; {{ Commas(kbank.total) }}
                </h3>
              </div>
              <div class="card-b d-flex justify-content-between">
                <small>เติมแล้ว {{ Commas(kbank.success) }}บาท</small>
                <small>เติมไม่ได้ {{ Commas(kbank.waiting) }}บาท</small>
              </div>
            </div>

          </div>
        </b-col>
      </b-row>

      <b-row class="mt-4">
        <b-col md="6">
          <b-card
            v-if="!showTable"
            no-body
          >
            <div>
              <div class="row mb-1 m-1">
                <div class="col-4">
                  <b-form-select
                    v-model="selectedmonth"
                    :options="month"
                    value-field="value"
                    text-field="text"
                  />
                </div>
                <div class="col-4">
                  <b-form-select
                    v-model="selectedyear"
                    :options="year"
                    value-field="value"
                    text-field="text"
                  />
                </div>
                <div class="col-2">
                  <b-button
                    variant="gradient-primary"
                    @click="getMonthly"
                  >
                    <i class="fal fa-search-dollar" />
                    ค้นหา
                  </b-button>
                </div>
                <div class="col-6" />
              </div>
              <div class="wrap-table100">
                <div class="table100 ver1">
                  <div class="table100-firstcol">
                    <table>
                      <thead>
                        <tr class="row100 head">
                          <th class="cell100 column1">
                            รายละเอียด/วันที่
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr class="row100 body">
                          <td class="cell100 column1">
                            สมัครวันนี้
                          </td>
                        </tr>

                        <tr class="row100 body">
                          <td class="cell100 column1">
                            จำนวนฝากวันนี้
                          </td>
                        </tr>

                        <!-- <tr class="row100 body">
                          <td class="cell100 column1">
                            Active
                          </td>
                        </tr> -->

                        <tr class="row100 body">
                          <td class="cell100 column1">
                            ยอดฝาก
                          </td>
                        </tr>

                        <tr class="row100 body">
                          <td class="cell100 column1">
                            ยอดถอน
                          </td>
                        </tr>
                        <tr class="row100 body">
                          <td class="cell100 column1">
                            ยอดรวม
                          </td>
                        </tr>

                        <tr class="row100 body">
                          <td class="cell100 column1">
                            โบนัส
                          </td>
                        </tr>

                        <tr class="row100 body">
                          <td class="cell100 column1">
                            Facebook
                          </td>
                        </tr>

                        <tr class="row100 body">
                          <td class="cell100 column1">
                            Google
                          </td>
                        </tr>

                        <tr class="row100 body">
                          <td class="cell100 column1">
                            Youtube
                          </td>
                        </tr>
                        <tr class="row100 body">
                          <td class="cell100 column1">
                            TikTok
                          </td>
                        </tr>
                        <tr class="row100 body">
                          <td class="cell100 column1">
                            SMS
                          </td>
                        </tr>

                        <tr class="row100 body">
                          <td class="cell100 column1">
                            เพื่อนแนะนำ
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <div class="wrap-table100-nextcols js-pscroll">
                    <div class="table100-nextcols">
                      <table>
                        <thead>
                          <tr class="row100 head">
                            <th
                              v-for="day in endDateTable"
                              :key="day"
                              class="cell100 column2"
                            >
                              {{ day }}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr class="row100 body">
                            <td
                              v-for="item in monthly.regis"
                              :key="item"
                              class="cell100 column2"
                            >
                              {{ Commas(item) }}
                            </td>
                          </tr>
                          <tr class="row100 body">
                            <td
                              v-for="item in monthly.nodeposit"
                              :key="item"
                              class="cell100 column2"
                            >
                              {{ Commas(item) }}
                            </td>
                          </tr>
                          <!-- <tr class="row100 body">
                            <td
                              v-for="item in monthly.active"
                              :key="item"
                              class="cell100 column2"
                            >
                              {{ item }}
                            </td>
                          </tr> -->
                          <tr class="row100 body">
                            <td
                              v-for="item in monthly.deposit"
                              :key="item"
                              class="cell100 column2"
                            >
                              {{ Commas(item) }}
                            </td>
                          </tr>
                          <tr class="row100 body">
                            <td
                              v-for="item in monthly.withdraw"
                              :key="item"
                              class="cell100 column2"
                            >
                              {{ Commas(item) }}
                            </td>
                          </tr>
                          <tr class="row100 body">
                            <td
                              v-for="item in monthly.total"
                              :key="item"
                              class="cell100 column2"
                            >
                              {{ Commas(item) }}
                            </td>
                          </tr>
                          <tr class="row100 body">
                            <td
                              v-for="item in monthly.bonus"
                              :key="item"
                              class="cell100 column2"
                            >
                              {{ Commas(item) }}
                            </td>
                          </tr>
                          <tr class="row100 body">
                            <td
                              v-for="item in monthly.facebook"
                              :key="item"
                              class="cell100 column2"
                            >
                              {{ Commas(item) }}
                            </td>
                          </tr>
                          <tr class="row100 body">
                            <td
                              v-for="item in monthly.google"
                              :key="item"
                              class="cell100 column2"
                            >
                              {{ Commas(item) }}
                            </td>
                          </tr>
                          <tr class="row100 body">
                            <td
                              v-for="item in monthly.youtube"
                              :key="item"
                              class="cell100 column2"
                            >
                              {{ Commas(item) }}
                            </td>
                          </tr>
                          <tr class="row100 body">
                            <td
                              v-for="item in monthly.tiktok"
                              :key="item"
                              class="cell100 column2"
                            >
                              {{ Commas(item) }}
                            </td>
                          </tr>
                          <tr class="row100 body">
                            <td
                              v-for="item in monthly.sms"
                              :key="item"
                              class="cell100 column2"
                            >
                              {{ Commas(item) }}
                            </td>
                          </tr>
                          <tr class="row100 body">
                            <td
                              v-for="item in monthly.friend"
                              :key="item"
                              class="cell100 column2"
                            >
                              {{ Commas(item) }}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <div class="table100-lastcol">
                    <table>
                      <thead>
                        <tr class="row100 head">
                          <th class="cell100 column1">
                            รวม
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr class="row100 body">
                          <td class="cell100 column1">
                            {{
                              Commas(
                                monthly.regis.reduce(
                                  (acc, ele) => acc + (!isNaN(ele) ? ele : 0),
                                  0,
                                ),
                              )
                            }}
                            คน
                          </td>
                        </tr>

                        <tr class="row100 body">
                          <td class="cell100 column1">
                            {{
                              Commas(
                                monthly.nodeposit.reduce(
                                  (acc, ele) => acc + (!isNaN(ele) ? ele : 0),
                                  0,
                                ),
                              )
                            }}
                            รายการ
                          </td>
                        </tr>

                        <!-- <tr class="row100 body">
                          <td class="cell100 column1">
                            {{
                              monthly.tactive
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                            }}
                            คน
                          </td>
                                                            .toFixed(2),
                        </tr> -->

                        <tr class="row100 body">
                          <td class="cell100 column1">
                            {{
                              Commas(
                                monthly.deposit.reduce(
                                  (acc, ele) => acc + (!isNaN(ele) ? ele : 0),
                                  0,
                                ),
                              )
                            }}
                            บาท
                          </td>
                        </tr>

                        <tr class="row100 body">
                          <td class="cell100 column1">
                            {{
                              Commas(
                                monthly.withdraw.reduce(
                                  (acc, ele) => acc + (!isNaN(ele) ? ele : 0),
                                  0,
                                ),
                              )
                            }}
                            บาท
                          </td>
                        </tr>

                        <tr class="row100 body">
                          <td class="cell100 column1">
                            {{
                              Commas(
                                monthly.total.reduce(
                                  (acc, ele) => acc + (!isNaN(ele) ? ele : 0),
                                  0,
                                )
                              )
                            }}
                            บาท
                          </td>
                        </tr>

                        <tr class="row100 body">
                          <td class="cell100 column1">
                            {{
                              Commas(
                                monthly.bonus.reduce(
                                  (acc, ele) => acc + (!isNaN(ele) ? ele : 0),
                                  0,
                                ),
                              )
                            }}
                            บาท
                          </td>
                        </tr>

                        <tr class="row100 body">
                          <td class="cell100 column1">
                            {{
                              Commas(
                                monthly.facebook.reduce(
                                  (acc, ele) => acc + (!isNaN(ele) ? ele : 0),
                                  0,
                                ),
                              )
                            }}
                            คน
                          </td>
                        </tr>

                        <tr class="row100 body">
                          <td class="cell100 column1">
                            {{
                              Commas(
                                monthly.google.reduce(
                                  (acc, ele) => acc + (!isNaN(ele) ? ele : 0),
                                  0,
                                ),
                              )
                            }}
                            คน
                          </td>
                        </tr>

                        <tr class="row100 body">
                          <td class="cell100 column1">
                            {{
                              Commas(
                                monthly.youtube.reduce(
                                  (acc, ele) => acc + (!isNaN(ele) ? ele : 0),
                                  0,
                                ),
                              )
                            }}
                            คน
                          </td>
                        </tr>
                        <tr class="row100 body">
                          <td class="cell100 column1">
                            {{
                              Commas(
                                monthly.tiktok.reduce(
                                  (acc, ele) => acc + (!isNaN(ele) ? ele : 0),
                                  0,
                                ),
                              )
                            }}
                            คน
                          </td>
                        </tr>
                        <tr class="row100 body">
                          <td class="cell100 column1">
                            {{
                              Commas(
                                monthly.sms.reduce(
                                  (acc, ele) => acc + (!isNaN(ele) ? ele : 0),
                                  0,
                                ),
                              )
                            }}
                            คน
                          </td>
                        </tr>

                        <tr class="row100 body">
                          <td class="cell100 column1">
                            {{
                              Commas(
                                monthly.friend.reduce(
                                  (acc, ele) => acc + (!isNaN(ele) ? ele : 0),
                                  0,
                                ),
                              )
                            }}
                            คน
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </b-card>
        </b-col>

        <b-col md="3">
          <h4>
            รายการฝากล่าสุด
          </h4>

          <div
            v-for="item in DepositLast"
            :key="item"
            class="card-bank2 font-weight-bolder"
          >
            <span
              style="color: blue"
            >{{ item.username }}
              <small class="text-secondary">-สถานะ-</small>
              <span
                v-if="item.status === 'success' || item.status === 'manual_success'"
                class="text-success"
              >{{ item.status }}</span>
              <span
                v-if="item.status === 'error'"
                class="text-danger"
              >{{ item.status }}</span>
              <span
                v-if="item.status === 'waithing' || item.status === 'processing'"
                class="text-warning"
              >{{ item.status }}</span>
            </span>
            <div class="d-flex justify-content-between">
              <h2 class="font-weight-bolder">
                {{ item.amount.toFixed(2) }}
              </h2>
              <div class="align-items-center d-flex">
                <div
                  class="text-center bank-img"
                  :style="`background-color: ${item.bg};`"
                >
                  <img
                    height="20"
                    :src="`/bankIcon/${item.path_photo}`"
                    alt=""
                  >
                </div>
                <i
                  class="far fa-angle-right"
                  style="margin: 10px"
                />
                <div
                  class="text-center bank-img"
                  style="background-color: #4c2786"
                >
                  <img
                    height="20"
                    src="/bankIcon/scb.svg"
                    alt=""
                  >
                </div>
              </div>
            </div>
            <span
              class="text-info"
            >{{ TimeAdd(item.created_at) }} <span class="text-secondary" />
            </span>
          </div>
        </b-col>

        <b-col
          md="3"
        >
          <h4>
            รายการถอนล่าสุด
          </h4>

          <div
            v-for="item in WithdrawLast"
            :key="item"
            class="card-bank3 font-weight-bolder"
          >
            <span
              style="color: blue"
            >{{ item.username }}
              <small class="text-secondary">-สถานะ-</small>
              <span
                v-if="item.status === 'success' || item.status === 'manual_success'"
                class="text-success"
              >{{ item.status }}</span>
              <span
                v-if="item.status === 'error'"
                class="text-danger"
              >{{ item.status }}</span>
              <span
                v-if="item.status === 'waithing' || item.status === 'processing'"
                class="text-warning"
              >{{ item.status }}</span>
            </span>
            <div class="d-flex justify-content-between">
              <h2 class="font-weight-bolder">
                {{ item.amount.toFixed(2) }}
              </h2>
              <div class="align-items-center d-flex">
                <div
                  class="text-center bank-img"
                  style="background-color: #ffc323"
                >
                  <img
                    height="20"
                    src="/bankIcon/bay.svg"
                    alt=""
                  >
                </div>
                <i
                  class="far fa-angle-right"
                  style="margin: 10px"
                />
                <div
                  class="text-center bank-img"
                  :style="`background-color: ${item.bg};`"
                >
                  <img
                    height="20"
                    :src="`/bankIcon/${item.path_photo}`"
                    alt=""
                  >
                </div>
              </div>
            </div>
            <span
              class="text-info"
            >{{ TimeAdd(item.created_at) }} <span
              class="text-secondary"
            /></span>
          </div>
        </b-col>
      </b-row>
    </div>
  </b-overlay>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BOverlay,
  BIconController,
  BFormSelect,
  BButton,
} from 'bootstrap-vue'

import momenttz from 'moment-timezone'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BOverlay,
    BIconController,
    BFormSelect,
    BButton,
  },
  data() {
    return {
      showTable: true,
      DepositLast: [],
      WithdrawLast: [],
      selectedyear: momenttz()
        .subtract(1, 'days')
        .format('YYYY'),
      selectedmonth: momenttz()
        .subtract(1, 'days')
        .format('M'),
      DepositLasts: [

      ],
      month: [
        { value: null, text: 'เลือกเดือน' },
        { value: '1', text: 'มกราคม' },
        { value: '2', text: 'กุมภาพันธ์' },
        { value: '3', text: 'มีนาคม' },
        { value: '4', text: 'เมษายน' },
        { value: '5', text: 'พฤษภาคม' },
        { value: '6', text: 'มิถุนายน' },
        { value: '7', text: 'กรกฎาคม' },
        { value: '8', text: 'สิงหาคม' },
        { value: '9', text: 'กันยายน' },
        { value: '10', text: 'ตุลาคม' },
        { value: '11', text: 'พฤศจิกายน' },
        { value: '12', text: 'ธันวาคม' },
      ],
      year: [
        { value: null, text: 'เลือกปี' },
        { value: '2021', text: '2564' },
        { value: '2022', text: '2565' },
        { value: '2023', text: '25646' },
      ],
      show: true,
      today: {
        deamount: 0,
        nodeposit: 0,
        memdeposit: 0,
        weamount: 0,
        debonus: 0,
        total: 0,
        regisnew: 0,
        activeNew: 0,
        memdepositfirstSum: 0,

      },
      monthly: {
        regis: [],
        nodeposit: [],
        active: [],
        deposit: [],
        withdraw: [],
        total: [],
        bonus: [],
        nobet: [],
        facebook: [],
        google: [],
        youtube: [],
        tiktok: [],
        sms: [],
        friend: [],
      },
      depositSeries: [
        {
          name: 'deposit',
          data: [],
        },
      ],
      withdrawSeries: [
        {
          name: 'withdraw',
          data: [],
        },
      ],
      bonusSeries: [
        {
          name: 'bonus',
          data: [],
        },
      ],
      chartData: {},
      customersPie: {
        series: [1200, 690, 258, 149],
        chartOptions: {
          chart: {
            toolbar: {
              show: false,
            },
          },
          labels: ['Totaltoday', 'Deposit', 'Withdraw', 'Bonus'],
          responsive: [
            {
              breakpoint: 992,
              options: {
                chart: {
                  height: 380,
                },
                legend: {
                  position: 'bottom',
                },
              },
            },
            {
              breakpoint: 576,
              options: {
                chart: {
                  height: 320,
                },
                plotOptions: {
                  pie: {
                    donut: {
                      labels: {
                        show: true,
                        name: {
                          fontSize: '1.5rem',
                        },
                        value: {
                          fontSize: '1rem',
                        },
                        total: {
                          fontSize: '1.5rem',
                        },
                      },
                    },
                  },
                },
                legend: {
                  show: true,
                },
              },
            },
          ],
          dataLabels: {
            enabled: true,
          },
          legend: {
            show: true,
            position: 'bottom',
            fontSize: '14px',
            fontFamily: 'Prompt',
            color: '#fff',
          },
          stroke: {
            show: false,
          },
          colors: ['#ffc800', '#28c76f', '#FF005D', '#ff9f43'],
        },
      },
      data: {},
      sunTrue: 0,
      scbconnect: {
        total: 0,
        waiting: 0,
        success: 0,
      },
      scbsms: {
        total: 0,
        waiting: 0,
        success: 0,
      },
      kbank: {
        total: 0,
        waiting: 0,
        success: 0,
      },
    }
  },
  created() {
    // this.interval = setInterval(() => {
    //   this.getToday()
    // }, 60000)
  },
  destroyed() {
    clearInterval(this.interval)
  },
  mounted() {
    this.getToday()
    this.getMonthly()
    this.GetDepositLast()
    this.GetWithdrawLast()
  },
  methods: {
    TimeAdd(data) {
      const x = momenttz(data).tz('Asia/Bangkok').add('hours', 7).format('DD/MM/YYYY HH:mm:ss')
      return x
    },
    GetDepositLast() {
      this.$http
        .get('https://uatapi.sabaideelotto.com/api/GetDepositLast')
        .then(response => {
          this.DepositLast = response.data
        })
        .catch(error => console.log(error))
    },
    GetWithdrawLast() {
      this.$http
        .get('https://uatapi.sabaideelotto.com/api/GetWithdrawLast')
        .then(response => {
          this.WithdrawLast = response.data
          // console.log(this.WithdrawLast)
        })
        .catch(error => console.log(error))
    },
    async getToday() {
      this.show = true
      await this.$http
        .get('https://uatapi.sabaideelotto.com/api/dashboard/today')
        .then(async response => {
          this.show = false
          this.today = response.data
          // eslint-disable-next-line no-restricted-syntax
          for await (const s of response.data.truemoney) {
            this.sunTrue += Number(s.amount)
          }
          // eslint-disable-next-line no-restricted-syntax
          for await (const s of response.data.scbconnect) {
            this.scbconnect.total += Number(s.amount)
            if (s.status === 'waiting') {
              this.scbconnect.waiting += Number(s.amount)
            } else {
              this.scbconnect.success += Number(s.amount)
            }
          }

          // eslint-disable-next-line no-restricted-syntax
          for await (const s of response.data.scbsms) {
            // if (s.status !== 'off') {
            this.scbsms.total += Number(s.amount)
            if (s.status === 'waiting') {
              this.scbsms.waiting += Number(s.amount)
            } else {
              this.scbsms.success += Number(s.amount)
            }
            // }
          }
          // eslint-disable-next-line no-restricted-syntax
          for await (const s of response.data.kbank) {
            this.kbank.total += Number(s.amount)
            if (s.status === 'waiting') {
              this.kbank.waiting += Number(s.amount)
            } else {
              this.kbank.success += Number(s.amount)
            }
          }

          // this.depositSeries[0].data = response.data.ddata
          // this.withdrawSeries[0].data = response.data.wdata
          // this.bonusSeries[0].data = response.data.bdata
        })
        .catch(error => console.log(error))
    },
    async getMonthly() {
      if (this.selectedmonth) {
        this.showTable = true
        this.ChangeEndDate(this.selectedyear, this.selectedmonth)
        this.ClearMonthly()
        const params = {
          selectedmonth: this.selectedmonth,
          selectedyear: this.selectedyear,
        }
        await this.$http
          .get('https://uatapi.sabaideelotto.com/api/dashboard/getmonthly', {
            params,
          })
          .then(response => {
            this.showTable = false
            console.log(response.data)
            // this.monthly = response.data
            this.CalMonthly(response.data)
            // console.log(this.monthly.regis)
          })
          .catch(error => console.log(error))
      }
    },
    ChangeEndDate(selectedyear, selectedmonth) {
      const FindEndDay = `${selectedyear}-${
        selectedmonth < 10 ? 0 + selectedmonth : selectedmonth
      }-01 00:00:00`
      this.endDate = Number(
        momenttz(FindEndDay)
          .endOf('month')
          .format('D'),
      )
      this.endDateTable = this.endDate + 1
    },
    CalMonthly(Report) {
      // eslint-disable-next-line no-plusplus
      for (let i = 1; i <= this.endDate; i++) {
        console.log(i)
        const Index = Report.findIndex(({ day }) => day === i)
        if (Index >= 0) {
          this.monthly.regis.push(Report[Index].regis)
          this.monthly.nodeposit.push(Report[Index].nodeposit)
          this.monthly.active.push(Report[Index].active)
          this.monthly.deposit.push(Report[Index].deposit)
          this.monthly.withdraw.push(Report[Index].withdraw)
          this.monthly.total.push(Report[Index].total)
          this.monthly.bonus.push(Report[Index].bonus)
          this.monthly.facebook.push(Report[Index].facebook)
          this.monthly.google.push(Report[Index].google)
          this.monthly.youtube.push(Report[Index].youtube)
          this.monthly.friend.push(Report[Index].friend)
          this.monthly.tiktok.push(Report[Index].tiktok)
          this.monthly.sms.push(Report[Index].sms)
        } else {
          this.monthly.regis.push(0)
          this.monthly.nodeposit.push(0)
          this.monthly.active.push(0)
          this.monthly.deposit.push(0)
          this.monthly.withdraw.push(0)
          this.monthly.total.push(0)
          this.monthly.bonus.push(0)
          this.monthly.facebook.push(0)
          this.monthly.google.push(0)
          this.monthly.youtube.push(0)
          this.monthly.friend.push(0)
          this.monthly.tiktok.push(0)
          this.monthly.sms.push(0)
        }
      }
    },
    ClearMonthly() {
      this.monthly = {
        regis: [],
        nodeposit: [],
        active: [],
        deposit: [],
        withdraw: [],
        total: [],
        bonus: [],
        facebook: [],
        google: [],
        youtube: [],
        tiktok: [],
        sms: [],
        friend: [],
      }
    },
    Commas(x) {
      if (!x) {
        return 0
      } if (x % 1 !== 0) {
        return Number(x).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      }
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
  },
}
</script>

<style scoped>
.card-bank-img {
  position: absolute;
  top: 45%;
  right: 8%;
}
.bank-img {
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  border-radius: 6px;
}
.card-bank1 {
  width: 100%;
  height: 100%;
  padding: 5px 15px;
  border-radius: 5px;
  border-left: 4px solid rgb(31, 184, 0);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  margin-bottom: 20px;
}
.card-bank2 {
  width: 100%;
  height: auto;
  padding: 5px 15px;
  border-radius: 5px;
  border-left: 4px solid rgb(0, 102, 255);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  margin-bottom: 20px;
}
.card-bank3 {
  width: 100%;
  height: auto;
  padding: 5px 15px;
  border-radius: 5px;
  border-left: 4px solid red;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  margin-bottom: 20px;
}
/*//////////////////////////////////////////////////////////////////
[ Scroll bar ]*/
.js-pscroll {
  position: relative;
  overflow: hidden;
}

.table100 .ps__rail-x {
  z-index: 1010;
  height: 6px;
  background-color: transparent;
  opacity: 1 !important;
  bottom: 10px;
}

.table100 .ps__rail-x::before {
  content: '';
  display: block;
  position: absolute;
  background-color: #ff0000;
  height: 100%;
  width: calc(100% - 30px);
  left: 15px;
  top: 0px;
}

.table100 .ps__rail-x .ps__thumb-x {
  height: 100%;
  bottom: 0;
  background-color: transparent;
  opacity: 1 !important;
}

.table100 .ps__rail-x .ps__thumb-x::before {
  content: '';
  display: block;
  position: absolute;
  background-color: #191919;
  height: 100%;
  width: calc(100% - 30px);
  top: 0;
  left: 15px;
}

/*//////////////////////////////////////////////////////////////////
[ Table ]*/

.limiter {
  width: 100%;
  margin: 0 auto;
  background: #fa71cd;
  background: -webkit-linear-gradient(bottom, #c471f5, #fa71cd);
  background: -o-linear-gradient(bottom, #c471f5, #fa71cd);
  background: -moz-linear-gradient(bottom, #c471f5, #fa71cd);
  background: linear-gradient(bottom, #c471f5, #fa71cd);
}

.container-table100 {
  max-width: 1366px;
  margin: 0 auto;
  min-height: 100vh;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding: 5px 100px;
}

.wrap-table100 {
  width: 100%;
}

/*//////////////////////////////////////////////////////////////////
[ Table ]*/
.table100 {
  background-color: #ffffff;
  color: rgb(0, 0, 0);
}

table {
  width: 100%;
}

th,
td {
  font-weight: unset;
  padding-right: 10px;
  height: 30px;
}

.column1 {
  color: rgb(0, 0, 0);
  background-color: #ffffff;
  width: 80px;
  padding-left: 20px;
}

.column2 {
  width: 120px;
  height: 25px;
  /* padding-left: 5px; */
}

.column3 {
  height: 25px;
  width: 120px;
}

.column4 {
  height: 25px;
  width: 120px;
}

.column5 {
  height: 25px;
  width: 120px;
}

.column6 {
  height: 25px;
  width: 120px;
}

.column7 {
  height: 25px;
  width: 120px;
}

.column8 {
  height: 25px;
  width: 120px;
}

.table100 th {
  padding-top: 21px;
  padding-bottom: 21px;
}

.table100 td {
  padding-top: 16px;
  padding-bottom: 16px;
}

/*==================================================================
[ Fix col ]*/
.table100 {
  width: 100%;
  position: relative;
}

.table100-firstcol {
  background-color: #fff;
  position: absolute;
  z-index: 1;
  width: 135px;
  top: 0;
  left: 0;
}

.table100-lastcol {
  background-color: #fff;
  position: absolute;
  z-index: 1;
  width: 155px;
  top: 0;
  right: 0;
}

.table100-firstcol table {
  background-color: #fff;
}

.wrap-table100-nextcols {
  width: 100%;
  overflow: auto;
  padding-left: 135px;
  padding-bottom: 28px;
}

.table100-nextcols table {
  table-layout: fixed;
}

.shadow-table100-firstcol {
  box-shadow: 8px 0px 10px 0px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 8px 0px 10px 0px rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: 8px 0px 10px 0px rgba(0, 0, 0, 0.05);
  -o-box-shadow: 8px 0px 10px 0px rgba(0, 0, 0, 0.05);
  -ms-box-shadow: 8px 0px 10px 0px rgba(0, 0, 0, 0.05);
}

.table100-firstcol table {
  background-color: transparent;
}

/*==================================================================
[ Ver1 ]*/

.table100.ver1 th {
  font-size: 14px;
  color: #ffffff;
  background: #313452;
  line-height: 0.1;
  text-transform: uppercase;
}

.table100.ver1 td {
  font-size: 0.9rem;
  line-height: 0.1;
}

.table100.ver1 .table100-firstcol td {
  color: #000000;
}

.table100.ver1 .table100-nextcols td {
  color: #000000;
}

.table100.ver1 tr {
  border-bottom: 0.1px solid #6b6b6b4f;
}
</style>
